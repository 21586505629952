import React, { useContext } from 'react';
import {IoMdArrowForward} from 'react-icons/io'
import {FiTrash} from 'react-icons/fi'
import { SidebarContext } from '../contexts/SidebarContext';
import { CartContext } from '../contexts/CartContext';
import CartItem from './CartItem';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const {isOpen, handleClose} = useContext(SidebarContext);
  const {cart, clearCart, total,} = useContext(CartContext)
  return(
    <div className={`${isOpen? 'right-0': '-right-full'} w-full bg-white fixed top-0
    h-full shadow-2xl md:w-[35vw] xl:w-[30vw]
    transition-all duration-300 z-20 px-4 lg:px-[35px]`}>

      <div className='flex items-center justify-between py-6
      border-b'>
        <div className='uppercase text-sm font-semibold'>Shopping Bag</div>

        <div onClick={handleClose} className='cursor-pointer w-8 h-8 flex justify-center
        items-center'>
          <IoMdArrowForward className='text-2xl'/>
        </div>
      </div>

      <div className='flex flex-col overflow-scroll gap-y-2 h-[55%] sm:h-[300px]
      overflow-y-auto '>
        {cart.map(item=>(
          <CartItem item={item} key={item.id}  />
        ))}
      </div>

      <div className='fixed bottom-0 md:w-[32%] w-[95%] bg-white flex flex-col gap-y-3 py-1 mt-0'>
        <div className='flex w-full justify-between
        items-center'>
          <div className='uppercase font-semibold'>
            <span className='mr-2'>Total:</span>$ {parseFloat(total).toFixed(2)}
          </div>

          <div onClick={clearCart} className='cursor-pointer py-4 bg-red-500 text-white
          w-12 h-12 flex justify-center items-center text-xl'>
            <FiTrash/>
          </div>
        </div>
        <Link to={'/'} onClick={handleClose} className='bg-gray-200 flex p-4 justify-center
        items-center text-primary w-full font-medium'>View Cart</Link>
        <Link  to={'/'} onClick={handleClose} className='bg-primary flex p-4 justify-center
        items-center text-white w-full font-medium'>Checkout</Link>
      </div>
    </div>
  )
};

export default Sidebar;
